<template>
  <div>
    <div class="aft_banner">
      <img :src="$host + banner.litpic" alt="" />
      <!-- <img :src="$host + banner.litpicm" alt="" /> -->
    </div>
    <div class="selectToggle">
      <div class="box flexCenter m160">
        <p class="">
          <router-link to="/cooperation/atrract">招商合作</router-link>
        </p>
        <p class="active">
          <router-link to="/cooperation/business">OEM-ODM业务</router-link>
        </p>
        <p class="">
          <router-link to="/cooperation/information">采购信息公示</router-link>
        </p>
      </div>
    </div>
    <div class="aft_title p80">
      <div class="box">
        <p>{{ yw_info.ywetitle }}</p>
        <div>{{ yw_info.title }}</div>
      </div>
    </div>

    <div class="business">
      <div>
        <p style="white-space: pre-wrap" v-html="yw_info.yedesc"></p>
      </div>
    </div>

    <div class="business1">
      <div class="aft_title p80">
        <div class="box">
          <p>{{ yw_info.yetitle }}</p>
          <div>{{ yw_info.yxtitle }}</div>
        </div>
      </div>
      <div class="box m160">
        <div class="content1">
          {{ yw_info.yms }}
        </div>
        <div class="banner">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in logo_list"
                :key="index"
              >
                <div class="item">
                  <img :src="$host + item" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="swiperPage flexCenter">
            <div class="prev button">
              <svg
                t="1655284142230"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2370"
                data-spm-anchor-id="a313x.7781069.0.i6"
                width="14"
                height="14"
              >
                <path
                  d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                  p-id="2371"
                  fill="#666"
                ></path>
              </svg>
            </div>
            <p></p>
            <div class="next button">
              <svg
                t="1655284142230"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2370"
                data-spm-anchor-id="a313x.7781069.0.i6"
                width="14"
                height="14"
              >
                <path
                  d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                  p-id="2371"
                  fill="#666"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="business2">
      <div class="aft_title p80">
        <div class="box">
          <p>{{ yw_info.cjetitle }}</p>
          <div>{{ yw_info.cjtitle }}</div>
        </div>
      </div>
      <div class="box">
        <div class="content1">
          {{ yw_info.cjdesc }}
        </div>
        <div class="content2">
          <div class="img">
            <img :src="$host + yw_info.cjimg" alt="" />
          </div>

          <div class="button">
            <img
              src="../assets/images/play1.png"
              alt=""
              :data-video="$host + yw_info.cjvideo"
              v-show="yw_info.cjvideo != ''"
              @click="videoPlay($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="business3">
      <div class="aft_title p80">
        <div class="box">
          <p>{{ yw_info.scxetitle }}</p>
          <div>{{ yw_info.scxtitle }}</div>
        </div>
      </div>
      <div class="box m160">
        <div class="content1">
          {{ yw_info.scxdesc }}
        </div>
        <div class="content2">
          <img
            src="../assets/images/play1.png"
            alt=""
            :data-video="$host + yw_info.scxvideo"
            v-show="yw_info.scxvideo != ''"
            @click="videoPlay($event)"
          />
        </div>
        <div class="content3 flexBetween">
          <div class="item">
            <div class="top">
              <!-- <i-count-up
                class="counter-value"
                :endVal="50"
                :delay="0"
                :options="options"
              >
              </i-count-up> -->

              <span>{{ yw_info.wsjrc }}</span> 亿片
            </div>
            <div class="bot">卫生巾年产</div>
          </div>
          <div class="item">
            <div class="top">
              <span>{{ yw_info.jqkrc }}</span> 亿片
            </div>
            <div class="bot">经期裤年产</div>
          </div>
          <div class="item">
            <div class="top">
              <span>{{ yw_info.znkrc }}</span> 亿片
            </div>
            <div class="bot">成人纸尿裤年产</div>
          </div>
          <div class="item">
            <div class="top">
              <span>{{ yw_info.sjkrc }}</span> 亿片
            </div>
            <div class="bot">成人失禁裤年产</div>
          </div>
        </div>
      </div>
    </div>
    <div class="business2">
      <div class="aft_title p80">
        <div class="box">
          <p>{{ yw_info.ysetitle }}</p>
          <div>{{ yw_info.ystitle }}</div>
        </div>
      </div>
      <div class="box">
        <div class="content1">
          {{ yw_info.ysdesc }}
        </div>
        <div class="content2">
          <div class="img">
            <img :src="$host + yw_info.ysimg" alt="" />
          </div>

          <div class="button">
            <img
              src="../assets/images/play1.png"
              alt=""
              :data-video="$host + yw_info.ysvideo"
              v-show="yw_info.ysvideo != ''"
              @click="videoPlay($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="layerVideo" v-show="isVideo">
      <div class="box">
        <span class="" @click="videoClose"
          ><svg
            t="1656307749828"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3067"
            width="24"
            height="24"
          >
            <path
              d="M687.603949 656.994302 541.10027 510.457878 687.603949 363.943966c8.829086-8.840342 8.829086-23.122627 0-31.961946-8.850575-8.840342-23.13286-8.840342-31.962969 0L509.138324 478.495932 362.623389 331.980997c-8.840342-8.818853-23.122627-8.818853-31.962969 0-8.840342 8.840342-8.840342 23.144116 0 31.984459l146.493445 146.514935L330.638931 656.994302c-8.819876 8.830109-8.819876 23.133883 0 31.962969 8.840342 8.829086 23.144116 8.829086 31.984459 0l146.514935-146.514935 146.502655 146.514935c8.830109 8.829086 23.112394 8.829086 31.962969 0C696.433034 680.129208 696.45657 665.824411 687.603949 656.994302z"
              p-id="3068"
              fill="#ffffff"
            ></path>
            <path
              d="M938.362063 510.457878c0-237.061161-192.174857-429.234995-429.247274-429.234995-237.062184 0-429.246251 192.173834-429.246251 429.234995 0 237.083673 192.185091 429.257507 429.246251 429.257507 97.345072 0 186.435133-33.110095 258.440074-87.677898 2.958378-3.354398 4.900613-7.636934 4.900613-12.449543 0-10.506285-8.521071-19.026332-19.027355-19.026332-5.431709 0-10.287297 2.162246-13.752212 5.826705l-0.2415 0c-64.456011 47.414893-143.745868 75.800383-229.876528 75.800383-214.679407 0-388.730489-174.073594-388.730489-388.719232 0-214.688617 174.051081-388.718209 388.730489-388.718209 214.688617 0 388.697743 174.029592 388.697743 388.718209 0 65.548902-15.386432 127.277802-44.081984 181.490517l0 0.309038c-0.508583 1.811252-1.104147 3.576455-1.104147 5.519714 0 10.507308 8.520047 19.028379 19.028379 19.028379 8.18952 0 15.054881-5.254677 17.703197-12.494569l0 0.132006C920.349827 648.38625 938.362063 581.536726 938.362063 510.457878z"
              p-id="3069"
              fill="#ffffff"
            ></path>
          </svg> </span
        ><video :src="videoSrc" controls></video>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import ICountUp from "vue-countup-v2";
export default {
  name: "cooperation",
  components: {
    ICountUp,
  },
  data() {
    return {
      videoSrc: "",
      isVideo: false,
      banner: {},
      yw_info: {},
      logo_list: [],
    };
  },
  watch: {
    logo_list: {
      handler() {
        //现在通过watch监听bannerList属性的属性值得变化
        //如果执行handler方法：代表组件实例身上这个属性已经有了【数组：四个元素】
        //当前这个函数执行：只能保证bannerList数据已经有了，但是无法保证v-for已经执行结束了
        //v-for执行完毕，才有结构【你现在watch当中没办法保证】
        //nextTick:在下次DOM更新循环结束之后执行延迟回调，在修改数据之后立即使用这个方法，获取更新后的DOM
        this.$nextTick(() => {
          //当执行这个回调的时候：保证服务器数据回来了，v-for执行完毕了

          new Swiper(".business1 .banner .swiper-container", {
            loop: true,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            spaceBetween: 0,
            slidesPerView: 6,
            speed: 800,
            navigation: {
              prevEl: ".business1 .box .prev",
              nextEl: ".business1 .box .next",
            },
            pagination: {
              el: ".business1 .box .swiperPage p",
              clickable: true,
            },
            breakpoints: {
              320: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 4,
              },
              1366: {
                slidesPerView: 4,
              },
              1600: {
                slidesPerView: 6,
              },
            },
          });
        });
      },
    },
  },

  created() {
    this.$http.get("api.php/Webinfo/getoem").then(
      function (res) {
        const business = JSON.parse(res.data);
        this.banner = business.banner;
        this.yw_info = business.yw_info;
        this.logo_list = business.yw_info.logo;

        // console.log(business);
      },
      function (res) {
        console.log(res.status);
      }
    );
  },
  methods: {
    videoPlay(e) {
      this.videoSrc = e.target.dataset.video;
      // console.log(e.target.dataset.video);
      this.isVideo = true;
    },
    videoClose() {
      this.isVideo = false;
    },
  },
};
</script>

<style lang="less">
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.business {
  font-size: 0.24rem;
  color: #666;
  line-height: 0.42rem;
  text-align: center;
  margin: 0.64rem 0 0.9rem;
}
.business1 {
  background: #f2f2f2;
  .aft_title {
    .box {
      p {
        color: #e5e5e5;
      }
    }
  }
  & > .box {
    margin-top: 0.54rem;
    .content1 {
      width: 53vw;
      margin: auto;
      font-size: 0.2rem;
      line-height: 0.42rem;
      color: #666;
      opacity: 0.8;
    }
    .banner {
      margin-top: 0.8rem;
      .item {
        border: 1px solid #ddd;
        height: 1.6rem;
        display: flex;
        align-items: center;
        text-align: center;
        img {
          max-width: 100%;
          max-height: 100%;
          margin: auto;
        }
      }
      .swiperPage {
        padding: 0.64rem 0 0.9rem;
        z-index: 2;
        div {
        }
        .button {
          width: 0.45rem;
          height: 0.45rem;
          display: flex;
          align-items: center;
          border-radius: 50%;
          border: 1px solid #ccc;
          cursor: pointer;
          transition: 0.4s;
          svg {
            margin: auto;
          }
          &:hover {
            border: 1px solid @color;
            svg {
              path {
                fill: @color;
              }
            }
          }
        }
        .prev {
          svg {
            transform: rotate(180deg);
          }
        }
        p {
          line-height: 0.4rem;
          margin: 0 0.45rem;
          span {
            width: 0.2rem;
            height: 2px;
            border-radius: 0;
            background: #ccc;
            margin: 0 5px;
            opacity: 1;
          }
          .swiper-pagination-bullet-active {
            background: @color;
          }
        }
      }
    }
  }
}
.business2 {
  padding-bottom: 0.9rem;
  & > .box {
    width: 53vw;
    margin: auto;
    .content1 {
      font-size: 0.2rem;
      line-height: 0.42rem;
      color: #666;
      opacity: 0.8;
      margin: 0.54rem 0 0.8rem;
    }
    .content2 {
      position: relative;
      .img {
        img {
          width: 100%;
          vertical-align: bottom;
        }
      }
      .button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        img {
          width: 1.3rem;
          cursor: pointer;
          animation: myBreath 1.5s linear infinite;
        }
      }
    }
  }
}
@keyframes myBreath {
  0% {
    transform: scale(0.88);
  }
  50% {
    transform: scale(100%);
  }
  100% {
    transform: scale(0.88);
  }
}
.business3 {
  background: url(../assets/images/bus2.png) no-repeat 50% 50%;
  background-size: cover;
  .aft_title {
    p {
      color: #fff;
      opacity: 0.1;
    }
    div {
      color: #fff;
    }
  }
  & > .box {
    .content1 {
      width: 53vw;
      font-size: 0.2rem;
      line-height: 0.42rem;
      color: #fff;
      opacity: 0.8;
      margin: 0.54rem auto 0.8rem;
    }
    .content2 {
      text-align: center;
      img {
        animation: myBreath 1.5s linear infinite;
        width: 1.3rem;
        cursor: pointer;
      }
    }
    .content3 {
      padding: 1.34rem 1.34rem 0.9rem;
      .item {
        .top {
          font-size: 0.32rem;
          color: @color;
          span {
            font-family: @gilroy_b;
            font-size: 0.56rem;
          }
        }
        .bot {
          font-family: @albb_m;
          font-size: 0.22rem;
          color: #fff;
          margin-top: 5px;
        }
      }
    }
  }
}
@media screen and(max-width:768px) {
  .business1 > .box .content1 {
    width: 90vw;
  }
  .business1 > .box .banner .item img {
    max-width: 100%;
  }
  .business2 > .box {
    width: 90vw;
  }
  .business3 > .box .content1 {
    width: 90vw;
  }
  .business3 > .box .content3 {
    padding: 0.34rem 0 0.5rem;
    .item {
      margin-bottom: 0.2rem;
    }
  }
  .business3 > .box .content3 .item {
    width: 50%;
    text-align: center;
  }
}
</style>
